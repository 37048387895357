<script setup lang="ts">
import { HouseHoldAppliance, type HouseHoldApplianceType } from '~/types'
import AC from "./AC.vue"
import DishWasher from "./DishWasher.vue"
import Fridge from "./Fridge.vue"
import Oven from "./Oven.vue"
import WashingMachine from "./WashingMachine.vue"
import WaterHeater from "./WaterHeater.vue"

const props = withDefaults(
  defineProps<{
    icon: HouseHoldApplianceType | string,
    label?: string,
    size?: number,
    active?: boolean
    inverted?: boolean
  }>(),
  { size: 10 }
)

const inactiveColor = computed(() => props.inverted ? 'text-white' : 'text-black group-hover/rich-check-box:text-white/60')
const activeColor = computed(() => props.inverted ? 'text-black' : 'text-white')
</script>

<template>
  <div class="house-hold-appliance flex justify-center items-center" :class="{
    'house-hold-appliance--active': active,
    [`h-${size} w-${size}`]: true,
    [`${activeColor}`]: active,
    [`${inactiveColor} hover:${activeColor}/80`]: !active,
  }">
    <AC v-if="icon == HouseHoldAppliance.AC" />
    <DishWasher v-else-if="icon == HouseHoldAppliance.DISH_WASHER" />
    <Fridge v-else-if="icon == HouseHoldAppliance.FRIDGE" />
    <Oven v-else-if="icon == HouseHoldAppliance.OVEN" />
    <WashingMachine v-else-if="icon == HouseHoldAppliance.WASHING_MACHINE" />
    <WaterHeater v-else-if="icon == HouseHoldAppliance.WATER_HEATER" />
  </div>
</template>