<template>
  <svg width="100%" height="100%" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.94287 11.3429L5.29117 9.99463C5.37544 9.91036 5.37544 9.76661 5.29117 9.68234C5.20691 9.59807 5.06316 9.59807 4.97889 9.68234L3.63058 11.0306C3.54632 11.1149 3.54632 11.2587 3.63058 11.3429C3.6752 11.3876 3.72972 11.4074 3.7892 11.4074C3.84869 11.4074 3.90322 11.3876 3.94783 11.3429H3.94287Z"
      fill="currentColor" />
    <path
      d="M6.67935 9.8757C6.59508 9.79143 6.45132 9.79143 6.36705 9.8757L3.82907 12.4137C3.7448 12.498 3.7448 12.6417 3.82907 12.726C3.87368 12.7706 3.92821 12.7904 3.9877 12.7904C4.04718 12.7904 4.10171 12.7706 4.14632 12.726L6.6843 10.188C6.76857 10.1037 6.76857 9.95997 6.6843 9.8757H6.67935Z"
      fill="currentColor" />
    <path
      d="M6.5702 11.2685L5.22189 12.6168C5.13762 12.7011 5.13762 12.8449 5.22189 12.9291C5.26651 12.9737 5.32104 12.9936 5.38052 12.9936C5.44 12.9936 5.49453 12.9737 5.53914 12.9291L6.88744 11.5808C6.97171 11.4965 6.97171 11.3528 6.88744 11.2685C6.80317 11.1843 6.65943 11.1843 6.57516 11.2685H6.5702Z"
      fill="currentColor" />
    <path
      d="M0.706055 0.130127V21.014H21.7336V0.130127H0.706055ZM1.84121 6.75763H20.5985V16.9244H1.84121V6.75763ZM20.5985 1.27024V5.62248H1.84121V1.27024H20.5985ZM1.84121 19.8788V18.0645H20.5985V19.8788H1.84121Z"
      fill="currentColor" />
    <path d="M13.4555 2.03857H8.98926V4.93346H13.4555V2.03857ZM12.5732 4.04616H9.87656V2.92092H12.5732V4.04616Z"
      fill="currentColor" />
    <path
      d="M5.74729 4.90865C6.5305 4.90865 7.16499 4.27415 7.16499 3.49094C7.16499 2.70774 6.5305 2.07324 5.74729 2.07324C4.96409 2.07324 4.32959 2.70774 4.32959 3.49094C4.32959 4.27415 4.96409 4.90865 5.74729 4.90865ZM5.74729 2.95559C6.04471 2.95559 6.28265 3.19352 6.28265 3.49094C6.28265 3.78836 6.04471 4.0263 5.74729 4.0263C5.44987 4.0263 5.21194 3.78836 5.21194 3.49094C5.21194 3.19352 5.44987 2.95559 5.74729 2.95559Z"
      fill="currentColor" />
    <path
      d="M16.6972 4.90865C17.4804 4.90865 18.1149 4.27415 18.1149 3.49094C18.1149 2.70774 17.4804 2.07324 16.6972 2.07324C15.914 2.07324 15.2795 2.70774 15.2795 3.49094C15.2795 4.27415 15.914 4.90865 16.6972 4.90865ZM16.6972 2.95559C16.9947 2.95559 17.2326 3.19352 17.2326 3.49094C17.2326 3.78836 16.9947 4.0263 16.6972 4.0263C16.3998 4.0263 16.1619 3.78836 16.1619 3.49094C16.1619 3.19352 16.3998 2.95559 16.6972 2.95559Z"
      fill="currentColor" />
    <path
      d="M18.2091 7.70435H4.23534C3.92305 7.70435 3.66528 7.95715 3.66528 8.2744C3.66528 8.59165 3.91809 8.84445 4.23534 8.84445H18.2091C18.5214 8.84445 18.7792 8.59165 18.7792 8.2744C18.7792 7.95715 18.5264 7.70435 18.2091 7.70435Z"
      fill="currentColor" />
  </svg>
</template>