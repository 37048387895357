<script setup lang="ts">
import HouseHoldApplianceIcon from '~/components/HouseHoldAppliances/HouseHoldApplianceIcon.vue'
import type { HouseHoldApplianceType } from '~/types';

const props = withDefaults(
  defineProps<{
    label?: string,
    icon: HouseHoldApplianceType | string,
    size?: number,
    value?: boolean,
    disabled?: boolean,
  }>(),
  { size: 20 }
)

const style = computed(() => {
  const classList: string[] = [
    'transition-colors',
    'duration-300',
    'ease-in-out'
  ]

  if (props.value) {
    classList.push('bg-primary hover:bg-primary')
  } else {
    classList.push('bg-neutral hover:bg-primary/60')
  }

  if (props.disabled) {
    classList.push('opacity-50 cursor-not-allowed focus:outline-none')
  }

  return classList.join(' ')
})
</script>

<template>
  <button
    class="group/rich-check-box flex items-center justify-center focus:outline-none focus:ring-none aspect-square rounded-full"
    :class="{
      [style]: true,
      [`h-${size} w-${size}`]: true,
    }" type="button">
    <HouseHoldApplianceIcon :icon="icon" :label="label" :size="10" :active="value" />
    <span class="sr-only">{{ label }}</span>
  </button>
</template>