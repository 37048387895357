<template>
  <svg width="100%" height="100%" viewBox="0 0 15 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.304932 0.190186V31.2408H14.135V0.190186H0.304932ZM1.53427 19.0565H12.9056V28.0584H1.53427V19.0565ZM12.9056 1.41456V17.8272H1.53427V1.41952H12.9056V1.41456ZM1.53427 30.0065V29.2828H12.9056V30.0065H1.53427Z"
      fill="currentColor" />
    <path
      d="M3.10075 17.1878C3.43783 17.1878 3.71542 16.9152 3.71542 16.5732V12.6571C3.71542 12.3201 3.44278 12.0425 3.10075 12.0425C2.75872 12.0425 2.48608 12.3151 2.48608 12.6571V16.5732C2.48608 16.9103 2.75872 17.1878 3.10075 17.1878Z"
      fill="currentColor" />
    <path
      d="M3.10075 19.7605C2.76367 19.7605 2.48608 20.0331 2.48608 20.3752V24.2912C2.48608 24.6283 2.75872 24.9059 3.10075 24.9059C3.44278 24.9059 3.71542 24.6332 3.71542 24.2912V20.3752C3.71542 20.0381 3.44278 19.7605 3.10075 19.7605Z"
      fill="currentColor" />
    <path d="M7.83456 4.07642H6.60522V6.56483H7.83456V4.07642Z" fill="currentColor" />
  </svg>
</template>