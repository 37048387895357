<script setup lang="ts">
const props = defineProps<{
  value: number,
  id: string,
}>()

defineEmits(['input'])

const dynamicDayWidth = computed(() => `${props.value}%`);
const dynamicNightWidth = computed(() => `${100 - props.value}%`);

const getValue = (event: Event): number => {
  if (event.target === null) {
    return -1
  }

  if (!('value' in event.target)) {
    return -1
  }

  return Number(event.target?.value)
}
</script>

<template>
  <div class="relative flex flex-col gap-4">
    <label :for="id" class="text-sm text-slate-600 flex w-full font-bold">
      <slot name="label" />
    </label>
    <input :id="id" :value="value" type="range" min="0" max="100" step="10"
      class="relative accent-primary focus:outline-none h-2 w-full cursor-pointer appearance-none rounded-lg"
      :style="{ '--dynamic-day-width': dynamicDayWidth, '--dynamic-night-width': dynamicNightWidth }"
      @input="$emit('input', getValue($event))" />
    <div class="flex w-full flex-1 flex-row justify-between">
      <div class="flex flex-col justify-start">
        <slot name="left" :value="value" />
      </div>
      <div class="flex flex-col justify-start">
        <slot name="right" :value="value" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
$bgColor: theme('colors.white');
$dayBgColor: theme('colors.orange.300');
$nightBgColor: theme('colors.indigo.300');


input[type="range"] {
  z-index: 0;
  // Night pattern
  background-color: $nightBgColor;
  // background: repeating-linear-gradient(-45deg, $nightBgColor , $nightBgColor 4px, $bgColor 4px, $bgColor 8px);


  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: var(--dynamic-day-width);
    border-radius: 16px;
    z-index: -1;
    // Day pattern
    background-color: $dayBgColor;
    // background: repeating-linear-gradient(45deg, $dayBgColor , $dayBgColor 4px, $bgColor 4px, $bgColor 8px);
  }
}
</style>