<script setup lang="ts">
import type { SelectOptionType } from '@/types'

withDefaults(defineProps<{
  value: number,
  id: string,
  steps?: SelectOptionType[]
}>(), {
  steps: () => [
    { id: 1, label: '1', value: 1 },
    { id: 2, label: '2', value: 2 },
    { id: 3, label: '3', value: 3 },
    { id: 4, label: '4', value: 4 },
    { id: 5, label: '5+', value: 5 },
  ]
})

defineEmits(['input'])

const getValue = (event: Event): number => {
  if (event.target === null) {
    return -1
  }

  if (!('value' in event.target)) {
    return -1
  }

  return Number(event.target?.value)
}
</script>

<template>
  <div class="flex flex-col gap-6">
    <label :for="id" class="text-sm text-slate-600 flex w-full font-bold">
      <slot name="label" />
    </label>
    <div class="relative">
      <input :id="id" :value="value" type="range" :min="`${steps[0].value}`" :max="`${steps[steps.length - 1].value}`"
        step="1"
        class="accent-primary focus:outline-none h-2 w-full cursor-pointer overflow-hidden appearance-none rounded-lg bg-slate-200"
        @input="$emit('input', getValue($event))" />
      <div class="absolute left-0 right-0 top-[6px] h-4 flex justify-between">
        <div v-for="step in steps" :key="step.id" class="h-4 w-4 cursor-pointer rounded-full" :class="{
          ['bg-primary']: Number(step.value) <= value,
          ['bg-slate-200 hover:bg-primary/50']: Number(step.value) > value,
        }" @click="$emit('input', Number(step.value))">
          <span class="absolute -top-5 w-4 text-xs text-center text-slate-600">

            {{ step.label }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="range"]::-webkit-slider-thumb {
    width: 16px;
    -webkit-appearance: none;
    appearance: none;
    height: 16px;
    background: #FFF;
    box-shadow: -405px 0 0 400px theme('colors.primary');
    border-radius: 50%;
  }
}
</style>