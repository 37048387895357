<template>
  <svg width="100%" height="100%" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.663 0.648926H0.776855V25.2902H1.40639C1.47084 25.5727 1.72364 25.7859 2.02602 25.7859C2.3284 25.7859 2.5812 25.5727 2.64564 25.2902H17.8141C17.8785 25.5727 18.1313 25.7859 18.4337 25.7859C18.7361 25.7859 18.9889 25.5727 19.0533 25.2902H19.6581V0.648926H19.663ZM2.0508 1.92783H18.3891V24.0162H2.0508V1.92783Z"
      fill="currentColor" />
    <path
      d="M15.2116 11.3165C15.2116 11.3165 15.1819 11.2719 15.162 11.252C14.017 9.77979 12.2275 8.83301 10.2199 8.83301C6.76489 8.83301 3.95923 11.6436 3.95923 15.0937C3.95923 18.5438 6.76985 21.3544 10.2199 21.3544C12.2275 21.3544 14.012 20.4026 15.162 18.9304C15.1819 18.9106 15.1967 18.8908 15.2116 18.866C16.0097 17.8151 16.4856 16.5064 16.4856 15.0887C16.4856 13.671 16.0097 12.3624 15.2166 11.3115L15.2116 11.3165ZM14.8002 17.6961C14.5474 17.25 14.359 16.7692 14.245 16.2735C14.245 16.2735 14.245 16.2735 14.245 16.2685C14.1607 15.8868 14.1161 15.4952 14.1161 15.0987C14.1161 14.7021 14.1607 14.3055 14.245 13.9288C14.359 13.4281 14.5424 12.9473 14.8002 12.5012C15.2364 13.2695 15.4892 14.1568 15.4892 15.1036C15.4892 16.0504 15.2364 16.9377 14.8002 17.706V17.6961ZM13.2437 16.3478C12.7381 17.5623 11.5434 18.3653 10.2199 18.3653C8.41557 18.3653 6.9483 16.898 6.9483 15.0937C6.9483 13.2893 8.41557 11.8221 10.2199 11.8221C11.5434 11.8221 12.7381 12.6301 13.2437 13.8396C13.1594 14.246 13.1198 14.6674 13.1198 15.0937C13.1198 15.52 13.1644 15.9364 13.2437 16.3478ZM10.2199 20.363C7.31512 20.363 4.95063 17.9985 4.95063 15.0937C4.95063 12.1889 7.31512 9.82441 10.2199 9.82441C11.7962 9.82441 13.209 10.5184 14.1756 11.6139C13.9724 11.9163 13.7989 12.2335 13.6551 12.5607C12.862 11.49 11.593 10.8257 10.2249 10.8257C7.87526 10.8257 5.96186 12.7391 5.96186 15.0887C5.96186 17.4384 7.87526 19.3518 10.2249 19.3518C11.598 19.3518 12.862 18.6875 13.6551 17.6168C13.7989 17.944 13.9773 18.2612 14.1756 18.5636C13.209 19.6591 11.7962 20.3531 10.2199 20.3531V20.363Z"
      fill="currentColor" />
    <path
      d="M3.31479 4.65912H6.60128C6.87392 4.65912 7.09698 4.43606 7.09698 4.16342C7.09698 3.89079 6.87392 3.66772 6.60128 3.66772H3.31479C3.04216 3.66772 2.81909 3.89079 2.81909 4.16342C2.81909 4.43606 3.04216 4.65912 3.31479 4.65912Z"
      fill="currentColor" />
    <path
      d="M17.1002 6.26025H3.31479C3.04216 6.26025 2.81909 6.48332 2.81909 6.75595C2.81909 7.02859 3.04216 7.25165 3.31479 7.25165H17.1002C17.3728 7.25165 17.5959 7.02859 17.5959 6.75595C17.5959 6.48332 17.3728 6.26025 17.1002 6.26025Z"
      fill="currentColor" />
    <path
      d="M14.0716 5.32831H14.6665C14.9391 5.32831 15.1622 5.10525 15.1622 4.83261C15.1622 4.55998 14.9391 4.33691 14.6665 4.33691H14.0716C13.799 4.33691 13.5759 4.55998 13.5759 4.83261C13.5759 5.10525 13.799 5.32831 14.0716 5.32831Z"
      fill="currentColor" />
    <path
      d="M16.3717 5.32831H16.9665C17.2392 5.32831 17.4622 5.10525 17.4622 4.83261C17.4622 4.55998 17.2392 4.33691 16.9665 4.33691H16.3717C16.099 4.33691 15.876 4.55998 15.876 4.83261C15.876 5.10525 16.099 5.32831 16.3717 5.32831Z"
      fill="currentColor" />
    <path
      d="M14.0716 3.98993H14.6665C14.9391 3.98993 15.1622 3.76687 15.1622 3.49424C15.1622 3.2216 14.9391 2.99854 14.6665 2.99854H14.0716C13.799 2.99854 13.5759 3.2216 13.5759 3.49424C13.5759 3.76687 13.799 3.98993 14.0716 3.98993Z"
      fill="currentColor" />
    <path
      d="M16.3717 3.98993H16.9665C17.2392 3.98993 17.4622 3.76687 17.4622 3.49424C17.4622 3.2216 17.2392 2.99854 16.9665 2.99854H16.3717C16.099 2.99854 15.876 3.2216 15.876 3.49424C15.876 3.76687 16.099 3.98993 16.3717 3.98993Z"
      fill="currentColor" />
    <path
      d="M10.2199 5.75954C11.0972 5.75954 11.8111 5.04573 11.8111 4.16834C11.8111 3.29096 11.0972 2.57715 10.2199 2.57715C9.34247 2.57715 8.62866 3.29096 8.62866 4.16834C8.62866 5.04573 9.34247 5.75954 10.2199 5.75954ZM10.2199 3.56359C10.552 3.56359 10.8197 3.83127 10.8197 4.16339C10.8197 4.49551 10.552 4.76318 10.2199 4.76318C9.88774 4.76318 9.62006 4.49551 9.62006 4.16339C9.62006 3.83127 9.88774 3.56359 10.2199 3.56359Z"
      fill="currentColor" />
    <path
      d="M8.26688 14.5086L9.78372 12.9917C9.88286 12.8926 9.88286 12.7389 9.78372 12.6398C9.68458 12.5406 9.53091 12.5406 9.43177 12.6398L7.91493 14.1566C7.81579 14.2558 7.81579 14.4094 7.91493 14.5086C7.9645 14.5581 8.02894 14.5829 8.08843 14.5829C8.14791 14.5829 8.21731 14.5581 8.26192 14.5086H8.26688Z"
      fill="currentColor" />
    <path
      d="M11.3403 12.8678C11.2412 12.7687 11.0875 12.7687 10.9883 12.8678L8.13808 15.7181C8.03894 15.8172 8.03894 15.9709 8.13808 16.07C8.18765 16.1196 8.25209 16.1444 8.31157 16.1444C8.37105 16.1444 8.44045 16.1196 8.48507 16.07L11.3353 13.2198C11.4345 13.1206 11.4345 12.967 11.3353 12.8678H11.3403Z"
      fill="currentColor" />
    <path
      d="M11.2162 14.4293L9.69935 15.9462C9.60022 16.0453 9.60022 16.199 9.69935 16.2981C9.74892 16.3477 9.81337 16.3725 9.87285 16.3725C9.93233 16.3725 10.0017 16.3477 10.0463 16.2981L11.5632 14.7813C11.6623 14.6821 11.6623 14.5285 11.5632 14.4293C11.464 14.3302 11.3104 14.3302 11.2112 14.4293H11.2162Z"
      fill="currentColor" />
  </svg>
</template>