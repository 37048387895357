<template>
  <svg width="100%" height="100%" viewBox="0 0 22 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.4695 2.87454H15.6464V1.57085C15.6464 1.0008 15.1854 0.539795 14.6153 0.539795H7.81928C7.24922 0.539795 6.78821 1.0008 6.78821 1.57085V2.87454H1.96506C1.14715 2.87454 0.48291 3.53878 0.48291 4.35668V27.2778C0.48291 28.0957 1.14715 28.76 1.96506 28.76H20.4646C21.2825 28.76 21.9467 28.0957 21.9467 27.2778V4.35668C21.9467 3.53878 21.2825 2.87454 20.4646 2.87454H20.4695ZM7.95311 2.87454V1.69973H14.4864V2.87454H7.95311ZM20.7917 4.35668V27.2778C20.7917 27.4563 20.648 27.6 20.4695 27.6H1.97001C1.79156 27.6 1.64781 27.4563 1.64781 27.2778V4.35668C1.64781 4.17823 1.79156 4.03448 1.97001 4.03448H20.4695C20.648 4.03448 20.7917 4.17823 20.7917 4.35668Z"
      fill="currentColor" />
    <path
      d="M18.0109 18.6228H4.4238C3.6059 18.6228 2.94165 19.287 2.94165 20.1049V24.824C2.94165 25.6419 3.6059 26.3062 4.4238 26.3062H18.0109C18.8288 26.3062 19.4931 25.6419 19.4931 24.824V20.1049C19.4931 19.287 18.8288 18.6228 18.0109 18.6228ZM18.3331 20.1049V24.824C18.3331 25.0025 18.1894 25.1462 18.0109 25.1462H4.4238C4.24535 25.1462 4.1016 25.0025 4.1016 24.824V20.1049C4.1016 19.9265 4.24535 19.7827 4.4238 19.7827H18.0109C18.1894 19.7827 18.3331 19.9265 18.3331 20.1049Z"
      fill="currentColor" />
    <path
      d="M7.0014 20.1843C5.74232 20.1843 4.72119 21.2055 4.72119 22.4645C4.72119 23.7236 5.74232 24.7448 7.0014 24.7448C8.26048 24.7448 9.28162 23.7236 9.28162 22.4645C9.28162 21.2055 8.26048 20.1843 7.0014 20.1843ZM8.12168 22.4645C8.12168 23.0792 7.62102 23.5848 7.0014 23.5848C6.38177 23.5848 5.88113 23.0842 5.88113 22.4645C5.88113 21.8449 6.38177 21.3443 7.0014 21.3443C7.62102 21.3443 8.12168 21.8449 8.12168 22.4645Z"
      fill="currentColor" />
    <path
      d="M15.4777 20.1843C14.2187 20.1843 13.1975 21.2055 13.1975 22.4645C13.1975 23.7236 14.2187 24.7448 15.4777 24.7448C16.7368 24.7448 17.758 23.7236 17.758 22.4645C17.758 21.2055 16.7368 20.1843 15.4777 20.1843ZM16.598 22.4645C16.598 23.0792 16.0974 23.5848 15.4777 23.5848C14.8581 23.5848 14.3574 23.0842 14.3574 22.4645C14.3574 21.8449 14.8581 21.3443 15.4777 21.3443C16.0974 21.3443 16.598 21.8449 16.598 22.4645Z"
      fill="currentColor" />
    <path
      d="M11.2397 21.0667C10.4664 21.0667 9.8418 21.6962 9.8418 22.4645C9.8418 23.2329 10.4713 23.8624 11.2397 23.8624C12.008 23.8624 12.6376 23.2329 12.6376 22.4645C12.6376 21.6962 12.008 21.0667 11.2397 21.0667ZM11.4776 22.4645C11.4776 22.5934 11.3686 22.7025 11.2397 22.7025C11.1108 22.7025 11.0017 22.5934 11.0017 22.4645C11.0017 22.3356 11.1058 22.2266 11.2397 22.2266C11.3735 22.2266 11.4776 22.3356 11.4776 22.4645Z"
      fill="currentColor" />
    <path
      d="M10.2184 16.7046H12.2161C12.9695 16.7046 13.5792 16.0949 13.5792 15.3414C13.5792 14.588 12.9695 13.9783 12.2161 13.9783H10.2184C9.46494 13.9783 8.85522 14.588 8.85522 15.3414C8.85522 16.0949 9.46494 16.7046 10.2184 16.7046ZM10.0152 15.3414C10.0152 15.2324 10.1044 15.1382 10.2184 15.1382H12.2161C12.3301 15.1382 12.4193 15.2274 12.4193 15.3414C12.4193 15.4555 12.3301 15.5447 12.2161 15.5447H10.2184C10.1044 15.5447 10.0152 15.4555 10.0152 15.3414Z"
      fill="currentColor" />
  </svg>
</template>