<template>
  <svg width="100%" height="100%" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.2803 0.778076H1.15951C0.852173 0.778076 0.599365 1.03088 0.599365 1.33822V8.15905C0.599365 8.2334 0.614236 8.30775 0.643978 8.38211L2.21535 12.0503C2.30457 12.2535 2.50781 12.3874 2.73087 12.3874H23.7089C23.9319 12.3874 24.1352 12.2535 24.2244 12.0503L25.7958 8.38211C25.8255 8.31271 25.8404 8.23836 25.8404 8.15905V1.33822C25.8404 1.03088 25.5876 0.778076 25.2803 0.778076ZM4.56001 11.2671C4.73846 11.1729 4.86238 10.9944 4.86238 10.7763V9.23967H21.5724V10.7763C21.5724 10.9895 21.6963 11.1729 21.8748 11.2671H4.56001ZM24.7201 8.04503L23.3371 11.2671H22.3903C22.5688 11.1729 22.6927 10.9944 22.6927 10.7763V8.67953C22.6927 8.3722 22.4399 8.11939 22.1326 8.11939H4.30224C3.99491 8.11939 3.7421 8.3722 3.7421 8.67953V10.7763C3.7421 10.9895 3.86603 11.1729 4.04448 11.2671H3.09769L1.71469 8.04503V1.89836H24.7201V8.04503Z"
      fill="currentColor" />
    <path
      d="M3.78182 3.99528H5.35319C5.66052 3.99528 5.91333 3.74247 5.91333 3.43514C5.91333 3.12781 5.66052 2.875 5.35319 2.875H3.78182C3.47449 2.875 3.22168 3.12781 3.22168 3.43514C3.22168 3.74247 3.47449 3.99528 3.78182 3.99528Z"
      fill="currentColor" />
    <path
      d="M8.96669 14.8361C9.17984 14.6131 9.16993 14.2562 8.94687 14.043C8.7238 13.8299 8.37185 13.8398 8.15374 14.0628C6.22547 16.0754 7.06816 17.2105 7.67787 18.0384C8.21819 18.767 8.54039 19.2082 7.5936 20.3929C7.40028 20.6358 7.43994 20.9878 7.68283 21.1811C7.78693 21.2654 7.91085 21.305 8.03478 21.305C8.19836 21.305 8.36194 21.2307 8.47099 21.0968C9.95809 19.2379 9.16002 18.1623 8.58005 17.3791C8.02486 16.6256 7.68779 16.1745 8.96174 14.846L8.96669 14.8361Z"
      fill="currentColor" />
    <path
      d="M12.6151 14.8361C12.8283 14.6131 12.8184 14.2562 12.5953 14.043C12.3722 13.8299 12.0203 13.8398 11.8022 14.0628C9.87391 16.0754 10.7166 17.2105 11.3263 18.0384C11.8666 18.767 12.1888 19.2082 11.242 20.3929C11.0487 20.6358 11.0884 20.9878 11.3313 21.1811C11.4354 21.2654 11.5593 21.305 11.6783 21.305C11.8418 21.305 12.0054 21.2307 12.1145 21.0919C13.6016 19.233 12.8035 18.1573 12.2235 17.3741C11.6683 16.6207 11.3313 16.1696 12.6052 14.8411L12.6151 14.8361Z"
      fill="currentColor" />
    <path
      d="M16.2584 14.8361C16.4716 14.6131 16.4666 14.2562 16.2386 14.043C16.0155 13.8299 15.6586 13.8398 15.4455 14.0628C13.5172 16.0754 14.3599 17.2105 14.9696 18.0384C15.5099 18.767 15.8321 19.2082 14.8854 20.3929C14.692 20.6358 14.7317 20.9878 14.9746 21.1811C15.0787 21.2654 15.2026 21.305 15.3216 21.305C15.4851 21.305 15.6487 21.2307 15.7578 21.0919C17.2449 19.233 16.4468 18.1573 15.8668 17.3741C15.3117 16.6256 14.9746 16.1696 16.2485 14.8411L16.2584 14.8361Z"
      fill="currentColor" />
    <path
      d="M19.9069 14.8361C20.12 14.6131 20.1101 14.2562 19.8871 14.043C19.664 13.8299 19.312 13.8398 19.0939 14.0628C17.1657 16.0754 18.0083 17.2105 18.6181 18.0384C19.1584 18.767 19.4855 19.2033 18.5338 20.3929C18.3405 20.6358 18.3801 20.9878 18.623 21.1811C18.7271 21.2654 18.851 21.305 18.975 21.305C19.1385 21.305 19.3021 21.2307 19.4112 21.0968C20.8983 19.2379 20.1002 18.1623 19.5202 17.3741C18.965 16.6256 18.628 16.1696 19.9019 14.8411L19.9069 14.8361Z"
      fill="currentColor" />
  </svg>
</template>