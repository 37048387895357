<script setup lang="ts">
import { storeToRefs } from 'pinia'
import type { EnergyRateType, SelectOptionType } from '@/types'
import { Client, EnergySubCategory, EnergyRateVariant, PriceVariant } from '@/types'
import InformationCircleIcon from '@heroicons/vue/20/solid/InformationCircleIcon'
import MoonIcon from '@heroicons/vue/20/solid/MoonIcon'
import SunIcon from '@heroicons/vue/20/solid/SunIcon'
import BoltIcon from '@heroicons/vue/20/solid/BoltIcon'
import FireIcon from '@heroicons/vue/20/solid/FireIcon'
import ButtonGroup from '~/components/UI/ButtonGroup.vue'
import CitySelectField from '~/components/UI/CitySelectField.vue'
import InputText from '~/components/UI/InputText.vue'
import PartitionRange from '~/components/UI/PartitionRange.vue'
import SelectField from '~/components/UI/SelectField.vue'
import Stepper from '~/components/UI/Stepper.vue'
import RichCheckBox from '~/components/UI/RichCheckBox.vue'
import Toggle from '~/components/UI/Toggle.vue'
import Button from '~/components/UI/Button.vue'

defineProps<{
  options: EnergyRateType[],
}>()

const emit = defineEmits(['update', 'submit'])

const { supplierList } = storeToRefs(useBrandStore())

const showElectricity = computed(() => energySubCategory.value !== EnergySubCategory.GAS)
const showGas = computed(() => energySubCategory.value !== EnergySubCategory.ELECTRICITY)

const {
  city,
  clientType,
  consumeInCubicMeters,
  consumeInKwh,
  consumeKnown,
  consumePartition,
  energySubCategory,
  estimatedConsumeInKwh,
  estimatedConsumeInCubicMeters,
  householdApplianceList,
  familyMembers,
  gasUsageList,
  priceVariant,
  rateVariant,
  supplier,
} = storeToRefs(useFilterStore())
const {
  setClientType,
  setConsumeInCubicMeters,
  setConsumeInKwh,
  setConsumePartition,
  setConsumeKnown,
  setCity,
  setEnergySubCategory,
  setProvince,
  setHouseHoldApplianceList,
  setFamilyMembers,
  setGasUsages,
  setPriceVariant,
  setRateVariant,
  setSupplier,
  setEstimatedConsumeInKwh,
  setEstimatedConsumeInCubicMeters,
  resetFilters,
} = useFilterStore()
const { getEstimatedKwHConsume, getEstimatedCubicMetersConsume } = usePriceEstimator()


const onCity = (value: any) => {
  setCity(value.nome)
  setProvince(value.provincia.nome)
}

watch(
  [consumePartition, householdApplianceList, gasUsageList, familyMembers],
  () => {
    const _householdApplianceList = householdApplianceList.value.reduce((acc: string[], { value, label }: SelectOptionType) => {
      if (value) acc.push(label)
      return acc
    }, [])
    const _gasUsageList = gasUsageList.value.reduce((acc: string[], { value, label }: SelectOptionType) => {
      if (value) acc.push(label)
      return acc
    }, [])

    const estimatedConsumeInKwh = getEstimatedKwHConsume(_householdApplianceList)
    const estimatedConsumeInCubicMeters = getEstimatedCubicMetersConsume(_gasUsageList)

    setEstimatedConsumeInKwh(estimatedConsumeInKwh)
    setEstimatedConsumeInCubicMeters(estimatedConsumeInCubicMeters)
  },
  { deep: true, immediate: true })
</script>

<template>
  <form class="isolate flex flex-1 flex-col gap-4">
    <ButtonGroup id="category" :value="energySubCategory" :options="[
      { id: 1, label: 'Tutti', value: EnergySubCategory.ALL },
      { id: 2, label: 'Luce', value: EnergySubCategory.ELECTRICITY },
      { id: 3, label: 'Gas', value: EnergySubCategory.GAS },
    ]" @input="setEnergySubCategory">
      <template #label> Tipo di offerta </template>
    </ButtonGroup>
    <SelectField id="supplier" :value="supplier" :options="supplierList" @input="setSupplier">

      <template #label> Fornitore </template>
    </SelectField>

    <ButtonGroup id="clientType" :value="clientType" :options="[
      { id: 1, label: 'Tutti', value: Client.ALL },
      { id: 2, label: 'Privato', value: Client.PRIVATE },
      { id: 3, label: 'Business', value: Client.BUSINESS },
    ]" @input="setClientType">

      <template #label>
        <div class="flex items-center gap-2">
          <span>Tipo cliente</span>
        </div>
      </template>
    </ButtonGroup>

    <CitySelectField id="city" :value="city" :query="city" @input="onCity" />

    <hr />

    <ButtonGroup id="priceType" :value="priceVariant" :options="[
      { id: 1, label: 'Tutti', value: PriceVariant.ALL },
      { id: 2, label: 'Fisso', value: PriceVariant.FIXED },
      { id: 3, label: 'Indicizzato', value: PriceVariant.INDEXED },
    ]" @input="setPriceVariant">

      <template #label>
        <div class="flex items-center gap-2">
          <span>Prezzo</span>
          <span v-tooltip="{
            value: `
              Il prezzo indicizzato nel campo dell'energia elettrica
              e del gas segue le fluttuazioni dei mercati energetici,
              variando periodicamente in base ai prezzi di mercato.

              Il prezzo fisso, invece, rimane invariato per la durata
              del contratto, indipendentemente dalle variazioni di
              prezzo nel mercato energetico.
            `
          }">
            <InformationCircleIcon class="h-5 w-5 text-primary cursor-pointer" />
          </span>
        </div>
      </template>
    </ButtonGroup>
    <ButtonGroup id="rateType" :value="rateVariant" :options="[
      { id: 1, label: 'Tutti', value: EnergyRateVariant.ALL },
      { id: 2, label: 'Monoraria', value: EnergyRateVariant.MONO },
      { id: 3, label: 'Bioraria', value: EnergyRateVariant.BI },
    ]" @input="setRateVariant">

      <template #label>
        <div class="flex items-center gap-2">
          <span>Tariffa</span>
          <span v-tooltip="{
            value: `
              La tariffa monoraria applica un costo fisso per
              l'energia elettrica consumata durante tutto il giorno,
              senza variazioni di prezzo.

              La tariffa bioraria, invece, prevede un costo diverso per
              l'energia consumata in fasce orarie specifiche, solitamente
              più basso durante le ore notturne e nei weekend.
            `
          }">
            <InformationCircleIcon class="h-5 w-5 text-primary cursor-pointer" />
          </span>
        </div>
      </template>
    </ButtonGroup>

    <hr />

    <PartitionRange v-if="showElectricity" id="consumePartition" :value="consumePartition" @input="setConsumePartition">

      <template #label> Ripartizione del consumo </template>

      <template #left="{ value }">
        <span class="flex items-center justify-start text-xs text-orange-500 font-medium">
          <span v-tooltip.focus.top="{
            value: 'Giorno: un valore piú alto indica un maggiore consumo nella fascia F1.',
          }" tabindex="1">
            <SunIcon class="h-4 w-4 " />
          </span>
          <span class="ml-1 font-bold">{{ value }}%</span>
        </span>
        <span class="text-[0.65rem] text-slate-400 flex justify-start font-normal">
          8:00 - 19:00
        </span>
      </template>

      <template #right="{ value }">
        <span class="flex items-center justify-end text-xs text-indigo-500 font-medium">
          <span v-tooltip.focus.top="{
            value: 'Notte: un valore piú alto indica un maggiore consumo nella fascia F2/F3.',
          }" tabindex="1">
            <MoonIcon class="h-4 w-4 " />
          </span>
          <span class="ml-1 font-bold">{{ 100 - value }}%</span>
        </span>
        <span class="text-[0.65rem] text-slate-400 flex justify-start font-normal">
          19:00 - 8:00
        </span>
      </template>
    </PartitionRange>

    <div class="flex flex-col gap-2">
      <div class="mb-4 flex items-center justify-between gap-6">
        <span class="text-sm text-slate-600 font-bold">
          Conosci il tuo consumo?
        </span>
        <Toggle id="consumeKnown" :value="consumeKnown" @change="setConsumeKnown" />
      </div>
      <div v-if="consumeKnown" class="flex flex-col gap-4">
        <InputText v-if="showElectricity" id="consumeInKwh" :model-value="consumeInKwh" type="number"
          placeholder="Inserisci il consumo in kWh" @input="setConsumeInKwh">

          <template #label> Consumo in kWh (annuo)</template>
        </InputText>
        <InputText v-if="showGas" id="consumeInCubicMeters" :model-value="consumeInCubicMeters" type="number"
          placeholder="Inserisci il consumo in m³" @input="setConsumeInCubicMeters">

          <template #label> Consumo in metri cubi (annuo) </template>
        </InputText>
      </div>
      <div v-else class="flex flex-col gap-4">
        <div v-if="showElectricity" class="flex flex-col items-center justify-between gap-4">
          <span class="w-full text-sm text-slate-600 font-bold">
            Quali elettrodomestici usi in casa?
          </span>
          <div class="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full place-items-center">
            <div v-for=" householdAppliance in householdApplianceList" :key="householdAppliance.id"
              class="flex flex-col gap-2 justify-center items-center w-20">
              <RichCheckBox v-if="householdAppliance.icon" class="w-full" :value="!!householdAppliance.value"
                :icon="householdAppliance.icon" :label="householdAppliance.label"
                @click="setHouseHoldApplianceList(householdAppliance.label)" />
              <span class="text-xs text-slate-600 font-light">
                {{ householdAppliance.label }}
              </span>
            </div>
          </div>
        </div>
        <Stepper id="familyMembers" :value="familyMembers" @input="setFamilyMembers">

          <template #label> Quanti siete in famiglia? </template>
        </Stepper>
        <div v-if="showGas" class="flex flex-col items-start justify-between gap-4">
          <span class="text-sm text-slate-600 font-bold">
            Per quali scopi utilizzi il gas?
          </span>
          <div class="flex flex-wrap justify-around gap-4 w-full">
            <div v-for="gasUsage in gasUsageList" :key="gasUsage.id"
              class="flex flex-col items-center justify-center gap-2">
              <Checkbox :model-value="gasUsage.value" :value="gasUsage.label" :name="gasUsage.label" binary
                @change="setGasUsages(gasUsage.label)" />
              <span class="text-xs text-slate-600 font-medium">
                {{ gasUsage.label }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex items-center p-2 border-2 border-primary/80 border-s-8 border-s-color-primary rounded-md">
          <div class="w-full flex flex-col gap-4 text-sm text-slate-600 font-light">
            <div class="flex gap-4 justify-between items-center">
              <span>
                Consumo annuo stimato
              </span>
              <span v-tooltip.top="{
                value: 'Valore stimato sui valori qui sopra inseriti.',
              }" class="relative h-full flex items-start">
                <InformationCircleIcon class="h-5 w-5 text-primary" />
              </span>
            </div>
            <div v-if="showElectricity" class="flex gap-1">
              <div class="flex h-[20px] w-[20px] items-center text-secondary">
                <BoltIcon />
              </div>
              <span>Luce: <span class="font-bold">{{ estimatedConsumeInKwh?.toFixed(2) }}</span></span> kWh
            </div>
            <div v-if="showGas" class="flex gap-1">
              <div class="flex h-[20px] w-[20px] items-center text-secondary">
                <FireIcon />
              </div>
              <span>Gas: <span class="font-bold">{{ estimatedConsumeInCubicMeters?.toFixed(2) }}</span></span> Smc
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="sm:hidden flex flex-col my-4">
      <Button class="h-10" @click="$emit('submit')">
        Applica
      </Button>
    </div>
    <div class="flex flex-col my-4">
      <Button class="h-10" @click="resetFilters">
        Reimposta filtri
      </Button>
    </div>
  </form>
</template>
